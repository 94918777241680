import waitInSeconds from './waitInSeconds';

const messageContainer = document.querySelector('.message-container');

messageContainer.addEventListener('click', e => {
   const exit = e.target.closest('svg');

   if (!exit) return;

   const messageEl = exit.closest('li');

   removeMessage(messageEl, false);
});

// Type can either be positive or negative
const showMessage = async (type, message) => {
   const messageEl = createElement(type, message);
   messageContainer.insertAdjacentElement('afterbegin', messageEl);
   messageEl.offsetHeight;
   messageEl.classList.add('active');

   removeMessage(messageEl, true);
};

const createElement = (type, message) => {
   const element = document.createElement('li');
   element.className = `${type}`;

   let emoji = '';
   if (type === 'positive') emoji = '✅';
   else if (type === 'negative') emoji = '⛔️';

   element.innerHTML = `
                        <p>${message}</p>
                        <span>${emoji}</span>
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" stroke-width="34.816"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"></path></g></svg>
   `;

   return element;
};

// If we want to wait to remove, then we add a timer -- else we just remove it instantly
const removeMessage = async (messageEl, waitToRemove) => {
   if (waitToRemove) await waitInSeconds(5);

   messageEl.classList.add('delete');

   await waitInSeconds(0.3);

   messageEl.remove();
};

const sendMessageToNextPage = (type, message) => {
   localStorage.setItem('message', JSON.stringify({ type, message }));
};

export { showMessage, sendMessageToNextPage };
